import React from 'react';
import { motion } from 'framer-motion';
import { Download, Check, AlertTriangle } from 'lucide-react';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';

export const DownloadPanel: React.FC = () => {
  const currentVersion = "v1.0 (5fd0cac)";

  const handleDownload = () => {
    window.location.href = "https://wax.rest/x.dll";
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-6 max-w-2xl mx-auto"
    >
      <Card className="bg-black/50 border-yellow-400/30 overflow-hidden">
        <CardHeader className="bg-yellow-400/10 border-b border-yellow-400/30">
          <CardTitle className="text-2xl font-bold text-yellow-400">Download wax.rest</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6 p-6">
          <div className="flex items-center justify-between">
            <span className="text-gray-300">Current Version:</span>
            <span className="text-yellow-400 font-semibold">{currentVersion}</span>
          </div>
          
          <Button 
            className="w-full bg-yellow-400 text-black hover:bg-yellow-300 transition-colors duration-300"
            onClick={handleDownload}
          >
            <Download className="mr-2 h-5 w-5" />
            Download Latest Version
          </Button>

          <div className="space-y-2 text-sm text-gray-400">
            <div className="flex items-center">
              <Check className="h-4 w-4 text-green-500 mr-2 flex-shrink-0" />
              <span>Windows 10 or Windows 11</span>
            </div>
            <div className="flex items-center">
              <Check className="h-4 w-4 text-green-500 mr-2 flex-shrink-0" />
              <span>Visual C++ Redistributable 2015-2022</span>
            </div>
          </div>

          <div className="bg-yellow-400/5 border border-yellow-400/20 rounded-lg p-4 text-sm text-gray-300">
            <div className="flex items-start">
              <AlertTriangle className="h-5 w-5 text-yellow-400 mr-2 flex-shrink-0 mt-0.5" />
              <p>
                By downloading, you agree to our Terms of Service and Privacy Policy. 
                Make sure you meet the system requirements before installing.
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </motion.div>
  );
};
