import React from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { Button } from "./components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card"
import { Zap, Shield, Eye, Settings, RefreshCw, ChevronRight, Github } from 'lucide-react'
import { useNavigate } from 'react-router-dom';

const WaxIcon: React.FC = () => (
  <motion.svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    animate={{
      filter: ["drop-shadow(0 0 8px #facc15)", "drop-shadow(0 0 16px #facc15)", "drop-shadow(0 0 8px #facc15)"],
    }}
    transition={{
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    }}
  >
    <path d="M16 2L4 8L16 14L28 8L16 2Z" stroke="#facc15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 16L16 22L28 16" stroke="#facc15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4 24L16 30L28 24" stroke="#facc15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </motion.svg>
);

const Discord: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M20.317 4.492c-1.53-.69-3.17-1.2-4.885-1.49a.075.075 0 0 0-.079.036c-.21.369-.444.85-.608 1.23a18.566 18.566 0 0 0-5.487 0 12.36 12.36 0 0 0-.617-1.23A.077.077 0 0 0 8.562 3c-1.714.29-3.354.8-4.885 1.491a.07.07 0 0 0-.032.027C.533 9.093-.32 13.555.099 17.961a.08.08 0 0 0 .031.055 20.03 20.03 0 0 0 5.993 2.98.078.078 0 0 0 .084-.026 13.83 13.83 0 0 0 1.226-1.963.074.074 0 0 0-.041-.104 13.201 13.201 0 0 1-1.872-.878.075.075 0 0 1-.008-.125c.126-.093.252-.19.372-.287a.075.075 0 0 1 .078-.01c3.927 1.764 8.18 1.764 12.061 0a.075.075 0 0 1 .079.009c.12.098.245.195.372.288a.075.075 0 0 1-.006.125c-.598.344-1.22.635-1.873.877a.075.075 0 0 0-.041.105c.36.687.772 1.341 1.225 1.962a.077.077 0 0 0 .084.028 19.963 19.963 0 0 0 6.002-2.981.076.076 0 0 0 .032-.054c.5-5.094-.838-9.52-3.549-13.442a.06.06 0 0 0-.031-.028zM8.02 15.278c-1.182 0-2.157-1.069-2.157-2.38 0-1.312.956-2.38 2.157-2.38 1.21 0 2.176 1.077 2.157 2.38 0 1.312-.956 2.38-2.157 2.38zm7.975 0c-1.183 0-2.157-1.069-2.157-2.38 0-1.312.955-2.38 2.157-2.38 1.21 0 2.176 1.077 2.157 2.38 0 1.312-.946 2.38-2.157 2.38z" />
  </svg>
);

const FeatureCard: React.FC<{ icon: React.ElementType; title: string; description: string }> = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Card className="bg-black/50 border-yellow-400/30 overflow-hidden backdrop-blur-lg h-full">
      <CardHeader className="relative p-4">
        <div className="flex items-center space-x-2">
          <Icon className="h-6 w-6 text-yellow-400" />
          <CardTitle className="text-lg font-bold text-yellow-400">{title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent className="p-4">
        <p className="text-gray-300 text-sm">{description}</p>
      </CardContent>
    </Card>
  </motion.div>
);

const LandingPage: React.FC = () => {
    const { scrollYProgress } = useViewportScroll();
    const backgroundY = useTransform(scrollYProgress, [0, 1], [0, 200]);
    const navbarBackground = useTransform(
      scrollYProgress,
      [0, 0.1],
      ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0.8)"]
    );
    const navigate = useNavigate();
  
    const scrollToSection = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    return (
      <div className="flex flex-col min-h-screen bg-black text-white overflow-hidden">
        <motion.header 
          className="fixed top-0 left-0 right-0 z-50 backdrop-blur-sm border-b border-yellow-400/20"
          style={{ backgroundColor: navbarBackground }}
        >
          <nav className="container mx-auto px-6 py-4 flex items-center justify-between">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="flex items-center space-x-2"
            >
              <WaxIcon />
              <span className="text-2xl font-bold text-yellow-400">wax.rest</span>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="flex space-x-6"
            >
              <motion.button
                onClick={() => scrollToSection('features')}
                className="text-sm text-yellow-400 hover:text-yellow-300 transition-colors duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                Features
              </motion.button>
              <motion.button
                onClick={() => scrollToSection('buy')}
                className="text-sm text-yellow-400 hover:text-yellow-300 transition-colors duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                Buy
              </motion.button>
              <motion.button
                onClick={() => navigate('/login')}
                className="text-sm text-yellow-400 hover:text-yellow-300 transition-colors duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                Login
              </motion.button>
            </motion.div>
          </nav>
        </motion.header>

        <main className="flex-grow">
          <section className="min-h-screen flex items-center justify-center relative overflow-hidden py-20">
            <motion.div
              className="absolute inset-0 z-0"
              style={{ y: backgroundY }}
            >
              <div className="absolute inset-0 bg-gradient-to-b from-yellow-400/20 to-black opacity-50"></div>
              {[...Array(100)].map((_, i) => (
                <motion.div
                  key={i}
                  className="absolute rounded-full bg-yellow-400"
                  style={{
                    top: `${Math.random() * 100}%`,
                    left: `${Math.random() * 100}%`,
                    width: `${Math.random() * 3 + 1}px`,
                    height: `${Math.random() * 3 + 1}px`,
                  }}
                  animate={{
                    scale: [1, 1.5, 1],
                    opacity: [0.7, 1, 0.7],
                    y: [0, Math.random() * 20 - 10],
                  }}
                  transition={{
                    duration: Math.random() * 3 + 2,
                    repeat: Infinity,
                    repeatType: 'reverse',
                  }}
                />
              ))}
            </motion.div>
            <div className="container mx-auto px-6 text-center relative z-10">
              <motion.h1
                className="text-6xl md:text-7xl font-bold mb-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-yellow-600">
                  wax.rest
                </span>
              </motion.h1>
              <motion.p
                className="text-xl md:text-2xl text-gray-300 mb-10 max-w-2xl mx-auto"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                Elevate your Minecraft 1.20 experience with our premium ghost client featuring an intuitive web GUI.
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6"
              >
                <Button size="lg" className="bg-yellow-400 text-black hover:bg-yellow-300 transition-colors duration-300 text-lg px-8 py-4" onClick={() => navigate('/login')}>
                  Get Started
                  <motion.span
                    animate={{ x: [0, 5, 0] }}
                    transition={{ duration: 1, repeat: Infinity }}
                    className="ml-2"
                  >
                    <ChevronRight className="h-5 w-5" />
                  </motion.span>
                </Button>
                <Button size="lg" variant="outline" className="border-yellow-400 text-yellow-400 hover:bg-yellow-400 hover:text-black transition-colors duration-300 text-lg px-8 py-4" onClick={() => {}}>
                  Learn More
                </Button>
              </motion.div>
            </div>
          </section>

          <section id="features" className="py-20 relative overflow-hidden">
            <div className="container mx-auto px-6">
              <motion.h2
                className="text-3xl md:text-4xl font-bold text-center mb-12"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-yellow-600">
                  Premium Features
                </span>
              </motion.h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <FeatureCard
                  icon={Eye}
                  title="Web GUI"
                  description="Manage your cheats seamlessly with our web-based GUI, allowing for quick adjustments."
                />
                <FeatureCard
                  icon={Shield}
                  title="Ghost Client"
                  description="Our ghost client offers high-quality, low-profile features for a unique advantage while remaining as elusive as a ghost."
                />
                <FeatureCard
                  icon={Settings}
                  title="Customizable Settings"
                  description="Tailor the client to your exact preferences with a wide range of customizable settings and features."
                />
                <FeatureCard
                  icon={Zap}
                  title="Premium Quality"
                  description="Experience top-notch quality with our Ghost Client, ensuring superior gameplay with your cheats."
                />
                <FeatureCard
                  icon={RefreshCw}
                  title="Frequent Updates"
                  description="Stay ahead with regular updates for new features to keep you ahead in-game."
                />
                <FeatureCard
                  icon={Shield}
                  title="Undetectable"
                  description="Our advanced cloaking technology ensures you stay under the radar."
                />
              </div>
            </div>
          </section>

          <section id="buy" className="py-20 relative overflow-hidden">
            <div className="container mx-auto px-6">
              <motion.div
                className="bg-yellow-400/10 p-10 rounded-lg backdrop-blur-lg max-w-3xl mx-auto"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="text-3xl font-bold text-yellow-400 mb-4 text-center">Ready to Dominate?</h2>
                <p className="text-gray-300 mb-6 text-center">
                  Join thousands of players and experience Minecraft like never before. Get wax.rest now!
                </p>
                <Button size="lg" className="w-full bg-yellow-400 text-black hover:bg-yellow-300 transition-colors duration-300 text-lg py-4" onClick={() => navigate('/login')}>
                  Buy wax.rest
                  <motion.span
                    animate={{ x: [0, 5, 0] }}
                    transition={{ duration: 1, repeat: Infinity }}
                    className="ml-2"
                  >
                    <ChevronRight className="h-5 w-5" />
                  </motion.span>
                </Button>
              </motion.div>
            </div>
          </section>
        </main>

        <footer className="bg-black py-8 border-t border-yellow-400/20">
          <div className="container mx-auto px-6">
            <div className="flex justify-between items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className="flex items-center space-x-2"
              >
                <WaxIcon />
                <span className="text-xl font-bold text-yellow-400">wax.rest</span>
              </motion.div>
              <div className="flex space-x-4">
                <motion.a
                  href="https://github.com/waxsoftworks"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-yellow-400 transition-colors duration-300"
                  whileHover={{ scale: 1.2, rotate: 15 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Github className="h-5 w-5" />
                </motion.a>
                <motion.a
                  href="https://discord.gg/C6rxGMxZBX"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-yellow-400 transition-colors duration-300"
                  whileHover={{ scale: 1.2, rotate: 15 }}
                  whileTap={{ scale: 0.8 }}
                >
                  <Discord className="h-5 w-5" />
                </motion.a>
              </div>
            </div>
            <div className="mt-4 text-center text-xs text-gray-500">
              &copy; 2024 wax.rest. All rights reserved. Not affiliated with Mojang AB.
            </div>
          </div>
        </footer>
      </div>
    )
}

export default LandingPage;